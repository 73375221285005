$(document).on("turbolinks:load", function() {
  CvInput.init("#cv_file");
});

let CvInput = {
  init: function(selector) {
    if (!!$(selector).length) {
      $(selector).change(function(e) {
        $(".filepath").html(e.target.files[0].name);
      });
    }
  }
};
